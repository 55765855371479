'use client';

import { ReactNode, useEffect, useRef, useState } from 'react';

import Image from 'next/image';

const logoGroups = [
  {
    title: 'Against red, gray or black',
    preview: '/images/logos/habanero-logo-preview-white.png',
    options: [
      {
        name: 'White',
        format: 'SVG',
        size: '3 KB',
        filename: '/images/logos/habanero-logo-white.svg',
      },
      {
        name: 'White',
        format: 'EPS',
        size: '446 KB',
        filename: '/images/logos/habanero-logo-white.eps',
      },
      {
        name: 'White',
        format: 'PNG',
        size: '5 KB',
        filename: '/images/logos/habanero-logo-large-white.png',
      },
      {
        name: 'White',
        format: 'PNG',
        size: '2 KB',
        filename: '/images/logos/habanero-logo-small-white.png',
      },
    ],
  },
  {
    title: 'Against white',
    preview: '/images/logos/habanero-logo-preview-red.png',
    options: [
      { name: 'Red', format: 'SVG', size: '3 KB', filename: '/images/logos/habanero-logo-red.svg' },
      {
        name: 'Red',
        format: 'EPS',
        size: '462 KB',
        filename: '/images/logos/habanero-logo-red.eps',
      },
      {
        name: 'Red',
        format: 'PNG',
        size: '5 KB',
        filename: '/images/logos/habanero-logo-large-red.png',
      },
      {
        name: 'Red',
        format: 'PNG',
        size: '2 KB',
        filename: '/images/logos/habanero-logo-small-red.png',
      },
    ],
  },
];

function LogoPopup({
  isOpen,
  onClose,
  anchorRef,
}: {
  isOpen: boolean;
  onClose: () => void;
  anchorRef: React.RefObject<HTMLDivElement | null>;
}) {
  if (!isOpen) return null;

  return (
    <div
      data-testid="logo-context-menu"
      className="fixed z-50 w-90 rounded-lg bg-white p-4 shadow-lg"
      style={{
        top: anchorRef.current?.getBoundingClientRect()?.bottom ?? 0,
        left: anchorRef.current?.getBoundingClientRect()?.left ?? 0,
      }}
      role="dialog"
      id="logo-popup"
      aria-modal="true"
      aria-labelledby="logo-popup-title"
    >
      <header className="flex items-center justify-between">
        <h3 id="logo-popup-title" className="text-lg font-semibold">
          Looking for our logo?
        </h3>
        <button
          onClick={onClose}
          className="cursor-pointer rounded-full p-1 text-slate-500 hover:text-red-800 focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:outline-none"
          aria-label="Close dialog"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            aria-hidden="true"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </header>

      <div className="flex gap-6">
        {logoGroups.map((group, groupIndex) => (
          <div key={groupIndex} className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              <p className="text-sm text-slate-600">{group.title}</p>
              <Image
                src={group.preview}
                alt={`Logo preview - ${group.title}`}
                className="h-auto"
                width={128}
              />
            </div>
            <div className="flex flex-col gap-2">
              {group.options.map((option, optionIndex) => (
                <a
                  key={optionIndex}
                  href={option.filename}
                  className="text-sm text-slate-700 hover:text-red-800 focus:underline"
                  download
                  aria-label={`Download ${option.name} logo in ${option.format} format (${option.size})`}
                >
                  {option.name} - {option.format.toUpperCase()} ({option.size})
                </a>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export function LogoContextMenu({ children }: { children: ReactNode }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const logoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (logoRef.current && !logoRef.current.contains(event.target as Node)) {
        setIsPopupOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleContextMenu = (e: React.MouseEvent) => {
    if (isPopupOpen) {
      return; // Allow default context menu if popup is already open
    }

    e.preventDefault();
    setIsPopupOpen(true);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    // Open popup on Shift+F10 or the Menu key (standard keyboard shortcut for context menu)
    if ((e.key === 'F10' && e.shiftKey) || e.key === 'ContextMenu') {
      e.preventDefault();
      setIsPopupOpen(true);
    }

    // Close popup on Escape key
    if (e.key === 'Escape' && isPopupOpen) {
      e.preventDefault();
      setIsPopupOpen(false);
    }
  };

  return (
    <div
      data-testid="logocontextmenu"
      ref={logoRef}
      onContextMenu={handleContextMenu}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      aria-haspopup="true"
      aria-controls="logo-popup"
    >
      {children}

      {logoRef?.current && (
        <LogoPopup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} anchorRef={logoRef} />
      )}
    </div>
  );
}
