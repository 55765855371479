import classnames from 'classnames';

type ChevronIconProps = {
  isExpanded: boolean;
};

export function ChevronIcon({ isExpanded }: ChevronIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="5"
      fill="none"
      className={classnames('cursor-pointer transition-transform duration-200', {
        'rotate-180': isExpanded,
      })}
    >
      <path
        fill="currentColor"
        d="M6.9.2 4 3.1 1.1.2a.7.7 0 0 0-.9 1l3.3 3.3a.7.7 0 0 0 1 0L7.8 1a.7.7 0 1 0-1-.9Z"
      />
    </svg>
  );
}
