'use client';

import classnames from 'classnames';

interface HamburgerButtonProps {
  isOpen: boolean;
  onClick: () => void;
}

export function HamburgerButton({ isOpen, onClick }: HamburgerButtonProps) {
  return (
    <button
      aria-label={isOpen ? 'Close menu' : 'Open menu'}
      className="flex h-10 w-10 cursor-pointer items-center justify-center"
      onClick={onClick}
      data-testid="mobile-menu-button"
    >
      <span className="sr-only">{isOpen ? 'Close menu' : 'Open menu'}</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" fill="none">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M1 8h20M1 1h20M1 15h20"
        />
      </svg>
    </button>
  );
}
