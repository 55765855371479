'use client';

import Link from 'next/link';

import { getAlgoliaResults } from '@algolia/autocomplete-js';
import { createAlgoliaInsightsPlugin } from '@algolia/autocomplete-plugin-algolia-insights';
import Icons from '@habanero-w9/icons';
import { liteClient } from 'algoliasearch/lite';
import { Button, Dialog, DialogTrigger, Modal, ModalOverlay } from 'react-aria-components';
import insightsClient from 'search-insights';

import { Autocomplete } from './autocomplete';

const searchClient = liteClient(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
);

insightsClient('init', {
  apiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
  appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
});

const algoliaInsightsPlugin = createAlgoliaInsightsPlugin({ insightsClient });

function Result({ hit, components }: any) {
  return <Link href={hit.url}>{hit.headers[0]}</Link>;
}

export function SearchButton() {
  return (
    <DialogTrigger data-testid="search-button">
      <Button
        className="flex h-10 w-10 items-center justify-center rounded-full hover:bg-slate-100 focus:ring-2 focus:ring-gray-200 focus:outline-none"
        aria-label="Search"
      >
        <Icons.Zoom className="h-5 w-5" />
      </Button>
      <ModalOverlay className="fixed inset-0 z-10 bg-black/50">
        <Modal className="fixed top-[20%] left-1/2 z-20 w-full max-w-2xl -translate-x-1/2">
          <Dialog className="rounded-lg bg-white shadow-xl">
            <div className="p-4">
              <Autocomplete
                openOnFocus={true}
                getSources={({ query }) => [
                  {
                    sourceId: 'pages',
                    getItems() {
                      return getAlgoliaResults({
                        searchClient,
                        queries: [
                          {
                            indexName: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME || '',
                            params: {
                              query,
                              hitsPerPage: 10,
                            },
                          },
                        ],
                      });
                    },
                    templates: {
                      item({ item, components }) {
                        return <Result hit={item} components={components} />;
                      },
                    },
                  },
                ]}
                plugins={[algoliaInsightsPlugin]}
              />
            </div>
          </Dialog>
        </Modal>
      </ModalOverlay>
    </DialogTrigger>
  );
}
