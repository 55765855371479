'use client';

import Link from 'next/link';

import { MAIN_NAVIGATION_QUERYResult } from '@/_lib/types';

export function NavigationList({
  parent,
  items,
  root = false,
}: {
  parent?: any;
  items: NonNullable<MAIN_NAVIGATION_QUERYResult>['mainNavigation']['items'];
  root?: boolean;
}) {
  if (!items) {
    return null;
  }

  return (
    <ul
      data-testid="navigation-list"
      className={`flex ${root ? 'flex-row' : 'flex-col'} m-0 list-none gap-6 p-0`}
    >
      {items.map((link, i) => (
        <li key={i}>
          {link.internal?.slug?.current || link.external ? (
            <Link
              className="hover:text-primary text-base font-semibold text-slate-900"
              href={link.internal?.slug?.current ? link.internal?.slug.current : link.external!}
              onClick={() => {
                if (parent) {
                  const popover = document.querySelector<HTMLElement>(`#menu-${parent._key}`);
                  popover?.hidePopover();
                }
              }}
            >
              {link.text}
            </Link>
          ) : (
            <button
              className="inline-flex cursor-pointer items-center gap-2 text-base font-semibold text-slate-900 hover:text-slate-900"
              popoverTarget={`menu-${link._key}`}
              // @ts-expect-error
              style={{ anchorName: `--menu-${link._key}` }}
            >
              <span>{link.text}</span>

              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" fill="none">
                <path
                  fill="currentColor"
                  d="M6.9 2.7 4 5.6 1.1 2.7a.7.7 0 0 0-.9 1L3.5 7a.7.7 0 0 0 1 0l3.3-3.4a.7.7 0 1 0-1-.9Z"
                />
              </svg>
            </button>
          )}

          {link.items && (
            <div
              popover="auto"
              id={`menu-${link._key}`}
              className="absolute top-20 m-0 w-full shadow"
            >
              <div className="wrapper mx-auto p-6">
                <div className="grid grid-cols-3 grid-rows-2 gap-6">
                  {link.items
                    .filter((link) => link.internal?.slug.current ?? link.external)
                    .map((innerLink, i) => (
                      <div
                        key={i}
                        className={`${link.items?.length === 4 && i === 2 ? 'col-start-1' : ''}`}
                      >
                        <Link
                          className="hover:text-primary flex flex-col gap-2 text-slate-900"
                          href={
                            innerLink.internal?.slug?.current
                              ? innerLink.internal?.slug.current
                              : innerLink.external!
                          }
                          onClick={() => {
                            if (parent) {
                              const popover = document.querySelector<HTMLElement>(
                                `#menu-${parent._key}`,
                              );
                              popover?.hidePopover();
                            }
                          }}
                        >
                          <div className="text-base font-semibold">{innerLink.text}</div>
                          <div className="text-sm">{innerLink.description}</div>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
}
