'use client';

import { useEffect, useState } from 'react';

import Link from 'next/link';

import classnames from 'classnames';

import { MOBILE_NAVIGATION_QUERYResult } from '@/_lib/types';

import { ButtonLink } from '../ButtonLink/button-link';
import { ChevronIcon } from './chevron-icon';
import { CloseButton } from './close-button.client';
import { HamburgerButton } from './hamburger-button.client';

export function MobileNavigationClient({
  items,
  contactLinkText,
  siteTitle,
}: {
  items: NonNullable<MOBILE_NAVIGATION_QUERYResult>['mainNavigation']['items'];
  contactLinkText: string;
  siteTitle: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>({});

  // Toggle expanded state for an item
  const toggleExpanded = (key: string) => {
    setExpandedItems((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // Prevent scrolling when menu is open
  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = '';
    }

    return () => {
      document.documentElement.style.overflow = '';
    };
  }, [isOpen]);

  if (!items) {
    return null;
  }

  return (
    <div className="lg:hidden" data-testid="mobile-navigation">
      {/* Hamburger button - positioned on the right */}
      <HamburgerButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />

      {/* Backdrop overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 z-40 bg-black/50 backdrop-blur-sm transition-opacity duration-300"
          onClick={() => setIsOpen(false)}
        />
      )}

      {/* Mobile mega menu - full width on sm */}
      <div
        className={classnames(
          'fixed inset-y-0 right-0 z-50 w-full bg-white shadow-xl transition-transform duration-300 sm:w-full md:max-w-md',
          {
            'translate-x-0': isOpen,
            'translate-x-full': !isOpen,
          },
        )}
        data-testid="mobile-navigation-menu"
      >
        <div className="flex h-full flex-col">
          {/* Header with site name instead of SiteLogo (server component) */}
          <div className="flex items-center justify-between border-b border-gray-100 p-6">
            <div className="text-xl font-bold">{siteTitle}</div>
            <CloseButton onClick={() => setIsOpen(false)} />
          </div>

          {/* Navigation items */}
          <div className="overflow-y-auto p-6">
            <ul className="flex flex-col">
              {items.map((link, i) => (
                <li key={i} className="border-b border-gray-100 py-2">
                  {link.internal?.slug?.current || link.external ? (
                    <Link
                      className={classnames('block py-2 text-xl font-semibold text-slate-900', {
                        'hover:text-primary': true,
                      })}
                      href={link.internal?.slug.current ?? link.external!}
                      onClick={() => setIsOpen(false)}
                    >
                      {link.text}
                    </Link>
                  ) : (
                    <div>
                      {/* Item with children - add chevron that toggles */}
                      <button
                        className="flex w-full cursor-pointer items-center justify-between py-2 text-xl font-semibold text-slate-900"
                        onClick={() => toggleExpanded(link._key)}
                      >
                        <span>{link.text}</span>
                        <ChevronIcon isExpanded={!!expandedItems[link._key]} />
                      </button>

                      {/* Expandable children section */}
                      {link.items && (
                        <div
                          className={classnames('overflow-hidden transition-all duration-300', {
                            'max-h-96 opacity-100': expandedItems[link._key],
                            'max-h-0 opacity-0': !expandedItems[link._key],
                          })}
                        >
                          <ul className="m-0">
                            {link.items
                              .filter((link) => link.internal?.slug.current ?? link.external)
                              .map((innerLink, j) => (
                                <li key={j}>
                                  <Link
                                    className={classnames('flex flex-col py-1 text-slate-700', {
                                      'hover:text-primary': true,
                                    })}
                                    href={innerLink.internal?.slug?.current ?? innerLink.external!}
                                    onClick={() => setIsOpen(false)}
                                  >
                                    <div className="text-base">{innerLink.text}</div>
                                    {innerLink.description && (
                                      <div className="text-sm text-slate-900">
                                        {innerLink.description}
                                      </div>
                                    )}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>

          {/* Footer with contact link */}
          <div className="mt-auto p-6">
            <ButtonLink className="w-full" href="/contact" onClick={() => setIsOpen(false)}>
              {contactLinkText}
            </ButtonLink>
          </div>
        </div>
      </div>
    </div>
  );
}
