'use client';

import { ComponentProps, useEffect, useState } from 'react';

import { GoogleTagManager } from '@next/third-parties/google';

type GTMParams = ComponentProps<typeof GoogleTagManager>;

export default function GoogleTagManagerClient(props: GTMParams) {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(!/headless/i.test(window.navigator.userAgent));
  }, []);

  if (load) {
    return <GoogleTagManager {...props} />;
  }

  return null;
}
