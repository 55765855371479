'use client';

import Image from 'next/image';
import { usePathname } from 'next/navigation';

import { Section } from '@/_components/Objects/Section/section';

import { ButtonLink } from '../ButtonLink/button-link';
import { Prose } from '../Prose/prose';
import LandAcknowledgementImage from './image.svg';

const EXCLUDED_PATHS = ['/land-acknowledgement', '/privacy-policy', '/terms-of-use'];

export const LandAcknowledgement = () => {
  const pathname = usePathname();

  if (EXCLUDED_PATHS.includes(pathname)) {
    return null;
  }

  return (
    <section className="mx-[calc(50%-50cqw)] mt-12 bg-yellow-50 px-4 py-16">
      <div className="wrapper relative mx-auto">
        <Prose className="relative z-1">
          <h2>Our commitment to reconciliation</h2>

          <p>
            Learn how Habanero is responding to the Truth and Reconciliation Calls to Action as a
            settler-owned company operating on Indigenous territories across what is now called
            Canada.
          </p>

          <ButtonLink href="/land-acknowledgement">Read about our commitment</ButtonLink>
        </Prose>

        <Image
          className="absolute right-0 -bottom-16 z-0"
          src={LandAcknowledgementImage}
          alt=""
          width={686}
        />
      </div>
    </section>
  );
};
