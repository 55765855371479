'use client';

interface CloseButtonProps {
  onClick: () => void;
}

export function CloseButton({ onClick }: CloseButtonProps) {
  return (
    <button
      aria-label="Close menu"
      className="flex h-10 w-10 cursor-pointer items-center justify-center"
      onClick={onClick}
    >
      <span className="sr-only">Close menu</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M18 6 6 18M6 6l12 12"
        />
      </svg>
    </button>
  );
}
