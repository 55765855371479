import React, { Fragment, createElement, useEffect, useLayoutEffect, useRef } from 'react';

import { autocomplete } from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';
import { Root, createRoot } from 'react-dom/client';

type AutocompleteParams = Parameters<typeof autocomplete>;
type AutocompleteProps = Omit<AutocompleteParams[0], 'container'>;

export function Autocomplete(props: AutocompleteProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const panelRootRef = useRef<Root | null>(null);
  const rootRef = useRef<HTMLElement | null>(null);

  useLayoutEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const search = autocomplete({
      container: containerRef.current,
      insights: true,
      renderer: { createElement, Fragment, render: () => {} },
      render({ children }, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root;

          panelRootRef.current?.unmount();
          panelRootRef.current = createRoot(root);
        }

        panelRootRef.current.render(children);
      },
      ...props,
    });

    return () => {
      search.destroy();
    };
  }, [props]);

  return <div ref={containerRef} />;
}
